import { dbCollections, settingsCollectionDocs } from "@/utils/FirebaseCollections";
import * as mongoQuery from "@/utils/MongoQueries/crudOperations/crudOperations";
import { mongodbSnapshot } from "@/utils/MongoQueries/crudOperationsWithSanpshot/crudOperationsWithSanpshot";
import { BSON } from "realm-web";
import {customField} from '../../plugins/customFieldView/helper.js';
import { apiRequest } from "@/services/index.js";
import * as env from '@/config/env';
const {setfinalCustomFieldsArray,setCustomFieldsArray} = customField();

export const setRules = ({commit,state}) => {
    return new Promise((resolve, reject) => {
        try {

            let options = {
                subCollection : dbCollections.RULES,
                watchFilter: {}
            }

            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get", env.RULES).then((res) => {
                            if(res.status === 200 && res?.data && res?.data?.length){
                                resolve(res?.data)
                                commit("mutateArrangedRules", res?.data);
                                res?.data.forEach((x) => {
                                    commit("mutateRules", {
                                        data: {...x, _id: x._id},
                                        op: "added",
                                    })
                                })
                            }else {
                                resolve([])
                                commit("mutateArrangedRules", []);
                                commit("mutateRules", {
                                    data: [],
                                    op: "added",
                                })
                            }
                        })
                        .catch((error) => {
                            reject(error)
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument;
                        commit("mutateRules", {
                            data: {...docData, _id: docData._id},
                            op: "added",
                        })
                        commit("mutateArrangedRules", state.rawRules);
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument;
                        commit("mutateRules", {
                            data: {...docData, _id: docData._id},
                            op: "modified",
                        })
                        commit("mutateArrangedRules", state.rawRules);
                    } else if(type === "delete") {
                        const docId = data.documentKey._id;
                        commit("mutateRules", {
                            data: {_id: docId},
                            op: "removed",
                        })
                        commit("mutateArrangedRules", state.rawRules);
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    });
}

export const setProjectRules = ({commit,state},payload) => {
    return new Promise((resolve, reject) => {
        try {
            if(state.projectRawRules){
                state.projectRawRules.forEach((data) => {
                    if(data.projectId == payload.pid) {
                        resolve();
                        return;
                    }else{
                        state.projectRawRules = [];
                        state.projectRules = [];
                    }
                })
            }
            let options = {
                subCollection : dbCollections.PROJECT_RULES,
                watchFilter: {
                    filter: {
                        $or: [
                            {
                                'operationType': 'delete'
                            },
                            {
                                'operationType': { $in: ['insert', 'update', 'replace'] },
                                'fullDocument.projectId': payload?.pid
                            }
                        ]
                    }
                }
            }

            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get", `${env.PROJECTRULES}/${payload.pid}`)
                        .then((res) => {
                            let data = res.data;
                            resolve(data)
                            commit("mutateArrangeProjectRules", {op:"added", data : data,projectId : payload.pid});
                            data.forEach((x) => {
                                commit("mutateProjectRules", {
                                    projectId : payload.pid,
                                    data: {...x, _id: x._id},
                                    op: "added",
                                })
                            })
                        })
                        .catch((error) => {
                            reject(error)
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument;
                        commit("mutateProjectRules", {
                            projectId : payload.pid,
                            data: {...docData, _id: docData._id},
                            op: "added",
                        })
                        commit("mutateArrangeProjectRules", {op:"added",data : state.projectRawRules,projectId : payload.pid});
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument;
                        commit("mutateProjectRules", {
                            projectId : payload.pid,
                            data: {...docData, _id: docData._id},
                            op: "modified",
                        })
                        commit("mutateArrangeProjectRules", {op:"modified",data : state.projectRawRules,projectId : payload.pid});
                    } else if(type === "delete") {
                        const docId = data.documentKey._id;
                        commit("mutateProjectRules", {
                            projectId : payload.pid,
                            data: {_id: docId},
                            op: "removed",
                        })
                        commit("mutateArrangeProjectRules", {op:"removed",data : state.projectRawRules,projectId : payload.pid});
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    });
}

export const setRoles = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {

            let options = {
                subCollection : dbCollections.SETTINGS,
                watchFilter: {
                    filter: {
                        $or: [
                            {
                                'operationType': 'delete'
                            },
                            {
                                'operationType': { $in: ['insert', 'update', 'replace'] },
                                'fullDocument.name':settingsCollectionDocs.ROLES
                            }
                        ]
                    }
                }
            }

            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get",env.SETTING_ROLES)
                        .then((res) => {
                            if(res.status === 200){
                                const roles = res.data;
                                commit("mutateRoles",{
                                    data : roles[0]?.settings,
                                    op : "added"
                                })
                                resolve(roles)
                            }else{
                                resolve([])
                            }
                        })
                        .catch((error) => {
                            console.error("ERROR in get roles: ", error);
                            reject(error);
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument?.settings;
                        commit("mutateRoles", {
                            data: docData,
                            op: "added",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument?.settings;
                        commit("mutateRoles", {
                            data: docData,
                            op: "modified",
                        })
                    } else if(type === "delete") {
                        commit("mutateRoles", {
                            op: "removed",
                        })
                    }
                }
            })

        } catch (error) {
            reject(error);
        }
    });
}

export const setCompanyUsers = ({commit},payload) => {
    return new Promise((resolve, reject) => {
        try {

            const snapQuery = {
                subCollection: dbCollections.COMPANY_USERS,
                watchFilter: {}
            }
            mongodbSnapshot(snapQuery, ({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get", env.API_MEMBERS).then((response) => {
                            const data = response.data.data;
                            data.forEach((x) => {
                                commit("mutateCompanyUsers", {
                                    data: {...x, _id: x._id, isCurrentUser: x.userId === payload.userId, requestId: x._id},
                                    op: "added",
                                })
                            })
                            resolve(data)
                        })
                        .catch((error) => {
                            console.error("Error in get company member users: ", error);
                            reject(error)
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument;
                        commit("mutateCompanyUsers", {
                            data: {...docData, _id: docData._id,isCurrentUser: docData.userId === payload.userId,requestId: docData._id},
                            op: "added",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument;
                        commit("mutateCompanyUsers", {
                            data: {...docData, _id: docData._id,isCurrentUser: docData.userId === payload.userId,requestId: docData._id},
                            op: "modified",
                        })
                    } else if(type === "delete") {
                        const docId = data.documentKey._id;
                        commit("mutateCompanyUsers", {
                            data: {_id: docId},
                            op: "removed",
                        })
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    });
}

export const setCompanyUserStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            let options = {
                subCollection : dbCollections.SETTINGS,
                watchFilter: {
                    filter: {
                        $or: [
                            {
                                'operationType': 'delete'
                            },
                            {
                                'operationType': { $in: ['insert', 'update', 'replace'] },
                                'fullDocument.name':settingsCollectionDocs.COMPANY_USER_STATUS
                            }
                        ]
                    }
                }
            }

            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest('get',env.SETTING_COMPANY_USER_STATUS)
                        .then((res) => {
                            if(res.status === 200){
                                const result = res.data;
                                commit("mutateCompanyUserStatus",{
                                    data: result[0]?.settings,
                                    op: "added"
                                })
                                resolve(result)
                            }else{
                                resolve([])
                            }
                        })
                        .catch((error) => {
                            console.error("ERROR in get company user status: ", error);
                            reject(error);
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument?.settings;
                        commit("mutateCompanyUserStatus", {
                            data: docData,
                            op: "added",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument?.settings;
                        commit("mutateCompanyUserStatus", {
                            data: docData,
                            op: "modified",
                        })
                    } else if(type === "delete") {
                        commit("mutateCompanyUserStatus", {
                            op: "removed",
                        })
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    });
}

export const setDesignations = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            let options = {
                subCollection:dbCollections.SETTINGS, 
                watchFilter: {
                    filter: {
                        $or: [
                            {
                                'operationType': 'delete'
                            },
                            {
                                'operationType': { $in: ['insert', 'update', 'replace'] },
                                'fullDocument.name':settingsCollectionDocs.DESIGNATIONS
                            }
                        ]
                    }
                }
            }
            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get",env.SETTING_DESIGNATION)
                        .then((res) => {
                            if(res.status === 200){
                                const data = res.data;
                                commit("mutateDesignations",{
                                    data: data[0]?.settings,
                                    op: "added"
                                })
                                resolve(data[0]);
                            }else{
                                resolve([]);
                            }
                        })
                        .catch((error) => {
                            console.error("ERROR in get designations: ", error);
                            reject(error);
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument?.settings;
                        commit("mutateDesignations", {
                            data: docData,
                            op: "added",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument?.settings;
                        commit("mutateDesignations", {
                            data: docData,
                            op: "modified",
                        })
                    } else if(type === "delete") {
                        commit("mutateDesignations", {
                            op: "removed",
                        })
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    });
}

export const setMileStoneStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            let options = {
                subCollection:dbCollections.SETTINGS, 
                watchFilter: {
                    filter: {
                        $or: [
                            {
                                'operationType': 'delete'
                            },
                            {
                                'operationType': { $in: ['insert', 'update', 'replace'] },
                                'fullDocument.name':settingsCollectionDocs.PROJECT_MILESTONE_STATUS
                            }
                        ]
                    }
                }
            }
            apiRequest("get", env.MILESTONE_STATUS).then((res) => {
                if(res.status === 200 && res?.data && res?.data?.[0].settings?.length){
                    commit("mutateProjectMilestoneStatus", {data:res?.data[0].settings,op:'inital'})
                    resolve(res[0]);
                }else{
                    resolve([]);
                }
            }).catch((error)=>{
                console.error("ERROR in get ProjectMilestoneStatus: ", error);
                reject(error);
            });
            mongodbSnapshot(options,(value)=>{
                if(value.error === null){
                    if(value.type === "insert"){
                        const insert = value.data.fullDocument;
                        commit("mutateProjectMilestoneStatus", {data:insert.settings,op:'inital'})
                        resolve(insert);
                    } else if(value.type === "update"){
                        const update = value.data.fullDocument;
                        commit("mutateProjectMilestoneStatus", {data:update.settings,op:'inital'})
                        resolve(update);
                    }
                }else{
                    reject(value.error);
                }
            });
        } catch (error) {
            reject(error);
        }
    });
}

export const setCompanies = ({commit},companyIds) => {
    return new Promise((resolve, reject) => {
        try {
            let options = {
                subCollection : dbCollections.COMPANIES,
                global: true,
                watchFilter: {
                    filter: {
                        "fullDocument._id": {$in: [...companyIds.map(x => BSON.ObjectID(x))]}
                    }
                }
            }

            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("post",`${env.COMPANYACTIONS}`,{companyIds: companyIds})
                        .then((res) => {
                            const data = res.data;
                            resolve(data)
                            data.forEach((x) => {
                                commit("mutateCompanies", {
                                    data: {...x, _id: x._id},
                                    op: "added",
                                })
                            })
                        })
                        .catch((error) => {
                            reject(error)
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument;
                        commit("mutateCompanies", {
                            data: {...docData, _id: docData._id},
                            op: "added",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument;
                        commit("mutateCompanies", {
                            data: {...docData, _id: docData._id},
                            op: "modified",
                        })
                    } else if(type === "delete") {
                        const docId = data.documentKey._id;
                        commit("mutateCompanies", {
                            data: {_id: docId},
                            op: "removed",
                        })
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    });
}

export const setFileExtentions = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            let options = {
                subCollection:dbCollections.SETTINGS, 
                watchFilter: {
                    filter: {
                        $or: [
                            {
                                'operationType': 'delete'
                            },
                            {
                                'operationType': { $in: ['insert', 'update', 'replace'] },
                                'fullDocument.name':settingsCollectionDocs.ALLOWED_FILE_EXTENSION
                            }
                        ]
                    }
                }
            }
            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get", env.FILE_EXTENSIONS).then((res) => {
                            if(res.status === 200 && res?.data && res?.data?.[0].settings?.length){
                                commit("mutateFileExtentions",{
                                    data: res?.data[0]?.settings,
                                    op: "inital"
                                })
                                resolve(res?.data[0])
                            }else{
                                resolve({});
                            }
                        })
                        .catch((error) => {
                            console.error("ERROR in get extensions: ", error);
                            reject(error);
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument?.settings;
                        commit("mutateFileExtentions", {
                            data: docData,
                            op: "inital",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument?.settings;
                        commit("mutateFileExtentions", {
                            data: docData,
                            op: "inital",
                        })
                    } else if(type === "delete") {
                        commit("mutateFileExtentions", {
                            op: "removed",
                        })
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    });
}

export const setProjectTabComponents = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            let options = {
                subCollection : dbCollections.PROJECT_TAB_COMPONENTS,
                watchFilter: {}
            }

            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get", `${env.PROJECTS_TABS}`)
                        .then((res) => {
                            const data = res.data;
                            resolve(data)
                            data.forEach((x) => {
                                commit("mutateProjectTabComponents", {
                                    data: {...x},
                                    op: "added",
                                })
                            })
                        })
                        .catch((error) => {
                            reject(error)
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument;
                        commit("mutateProjectTabComponents", {
                            data: {...docData},
                            op: "added",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument;
                        commit("mutateProjectTabComponents", {
                            data: {...docData},
                            op: "modified",
                        })
                    } else if(type === "delete") {
                        const docId = data.documentKey._id;
                        commit("mutateProjectTabComponents", {
                            data: {_id: docId},
                            op: "removed",
                        })
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    });
}

export const setCompanyPriority = ({ commit }) => {
    return new Promise((resolve, reject) => {
        try {
            let options = {
                subCollection:dbCollections.SETTINGS, 
                watchFilter: {
                    filter: {
                        $or: [
                            {
                                'operationType': 'delete'
                            },
                            {
                                'operationType': { $in: ['insert', 'update', 'replace'] },
                                'fullDocument.name':settingsCollectionDocs.TASK_PRIORITIES
                            }
                        ]
                    }
                }
            }
            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get", env.TASK_PRIORITY).then((res) => {
                            if(res.status === 200 && res?.data && res?.data?.[0].settings?.length){
                                commit("mutateCompanyPriority",{
                                    data: res?.data[0].settings,
                                    op: "inital"
                                })
                                resolve(res?.data[0].settings)
                            }else{
                                resolve([]);
                            }
                        })
                        .catch((error) => {
                            console.error("ERROR in getting company priorities settings: ", error);
                            reject(error);
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument;
                        commit("mutateCompanyPriority", {
                            data: docData.settings,
                            op: "inital",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument;
                        commit("mutateCompanyPriority", {
                            data: docData.settings,
                            op: "inital",
                        })
                    } else if(type === "delete") {
                        commit("mutateCompanyPriority", {
                            op: "removed",
                        })
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    });
}

export const setCompayDateFormat = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            let options = {
                subCollection:dbCollections.SETTINGS, 
                watchFilter: {
                    filter: {
                        $or: [
                            {
                                'operationType': 'delete'
                            },
                            {
                                'operationType': { $in: ['insert', 'update', 'replace'] },
                                'fullDocument.name':settingsCollectionDocs.COMMON_DATE_FORMAT
                            }
                        ]
                    }
                }
            }
            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get", env.COMMON_DATE_FORMATE).then((res) => {
                            if(res.status === 200 && res?.data && res?.data?.[0].settings?.length){
                                commit("mutateCompanyDateFormat",{
                                    data: res?.data[0]?.settings,
                                    op: "added"
                                });
                                resolve(res?.data[0])
                            }else{
                                resolve({})
                            }
                        })
                        .catch((error) => {
                            console.error("ERROR in get date format: ", error);
                            reject(error);
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument?.settings;
                        commit("mutateCompanyDateFormat", {
                            data: docData,
                            op: "added",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument?.settings;
                        commit("mutateCompanyDateFormat", {
                            data: docData,
                            op: "modified",
                        })
                    } else if(type === "delete") {
                        commit("mutateCompanyDateFormat", {
                            op: "removed",
                        })
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    });
}

export const setNotificationRules = ({commit}, payload) => {
    return new Promise((resolve, reject) => {
        try {
            apiRequest("get",`${env.NOTIFICATION}/${payload.userId}`).then((result) => {
                if(result.status === 200){
                    commit("mutateNotificationSettings",{
                        data: result?.data || {},
                        op: "added"
                    })
                    resolve(result?.data || {})
                }else{
                    commit("mutateNotificationSettings",{
                        data: {},
                        op: "added"
                    })
                    resolve({})
                }
            }).catch((error) => {
                console.error("Error in getting notification settings: ", error);
                reject(error);
            })
        } catch (error) {
            reject(error);
        }

    })
}

export const setTaskType = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            apiRequest("get", env.TASK_TYPE_TEMPLATE).then((res) => {
                if(res.status === 200 && res?.data && res?.data?.length){
                    resolve(res?.data)
                    res?.data.forEach((x) => {
                        commit("mutateTaskType", {
                            data: {...x, _id: x._id},
                            op: "added",
                        })
                    })
                }else{
                    resolve([]);
                }
            })
            .catch((error) => {
                reject(error)
            })
        } catch (error) {
            reject(error);
        }
    });
}
export const setTaskStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            apiRequest("get", env.TASK_STATUS_TEMPLATE).then((res) => {
                if(res.status === 200 && res?.data && res?.data?.length){
                    resolve(res?.data)
                    res?.data?.forEach((x) => {
                        commit("mutateTaskStatus", {
                            data: {...x, _id: x._id},
                            op: "added",
                        })
                    })
                }else{
                    resolve([]);
                }
            })
            .catch((error) => {
                reject(error)
            })
        }
        catch(error){
            reject(error);
        }
    })
}
export const setCategory = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            apiRequest("get",env.SETTING_CATEGORY)
            .then((res)=>{
                const category = res.data;
                commit("mutateCategory", category[0].settings)
                resolve(category[0]);
            }).catch((error)=>{
                console.error("ERROR in get mutateCategory: ", error);
                reject(error);
            });
        } catch (error) {
            reject(error);
            console.error("ERROR mutateCategory",error)
        }
    });
}

export const setMileStoneWeeklyRange = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            let options = {
                subCollection:dbCollections.SETTINGS, 
                watchFilter: {
                    filter: {
                        $or: [
                            {
                                'operationType': 'delete'
                            },
                            {
                                'operationType': { $in: ['insert', 'update', 'replace'] },
                                'fullDocument.name':settingsCollectionDocs.HOURLY_MILESTONE_WEEKLY_RANGE
                            }
                        ]
                    }
                }
            }
            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get", env.MILESTONE_RANGE).then((res) => {
                            if(res.status === 200 && res?.data && res?.data?.[0].settings?.length){
                                commit("mutateProjectMilestoneWeeklyRange",{
                                    data: res?.data[0].settings[0],
                                    op: "added"
                                })
                                resolve(res?.data[0].settings[0]);
                            }else{
                                resolve([]);
                            }
                        }).catch((error) => {
                            console.error("ERROR in get project milestone weekly range settings: ", error);
                            reject(error);
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument;
                        commit("mutateProjectMilestoneWeeklyRange", {
                            data: docData.settings[0],
                            op: "added",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument;
                        commit("mutateProjectMilestoneWeeklyRange", {
                            data: docData.settings[0],
                            op: "modified",
                        })
                    } else if(type === "delete") {
                        commit("mutateProjectMilestoneWeeklyRange", {
                            op: "removed",
                        })
                    }
                }
            })
            }          catch (error) {
                    reject(error);
                }
    });
}

export const setTeams = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            let options = {
                subCollection : dbCollections.TEAMS_MANAGEMENT,
                watchFilter: {}
            }

            mongodbSnapshot(options, ({ error, data, type }) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get", env.TEAMS).then((response) => {
                            var data = response.data;
                            if(data.length > 0){
                                data.filter((item)=>{
                                        item['isEdit'] = false;
                                        item['isPopupOpen'] = false;
                                })
                            }
                            data.map(item => {
                                commit("mutateTeams",{
                                    data: item,
                                    op: "added"
                                })
                            })
                            resolve(data)
                        })
                        .catch((error) => {
                            console.error("ERROR in getting team settings: ", error);
                            reject(error);
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument;
                        var ins = Object.assign(docData, {'isEdit': false, 'isPopupOpen': false});
                        commit("mutateTeams", {
                            data: ins,
                            op: "added",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument;
                        var upd = Object.assign(docData, {'isEdit': false, 'isPopupOpen': false});
                        commit("mutateTeams", {
                            data: upd,
                            op: "modified",
                        })
                    } else if (type === "delete") {
                        const docData = data.documentKey;
                        commit("mutateTeams", {
                            data:docData,
                            op: "removed",
                        })
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    });
}
export const setCustomFields = ({commit}) => {
    setCustomFieldsArray({commit});
}


export const  setRestrictedExtensions = ({commit}) => {
    return new Promise((resolve, reject) => {
        try{
            let options = {
                subCollection : dbCollections.RESTRICTED_EXTENSIONS,
                global: true,
                watchFilter: {}
            }

            mongodbSnapshot(options,({error, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        apiRequest("get", env.RESTRICTED_EXTENSIONS).then((response) => {
                            const data = response.data.data;
                            commit("mutateRestrictedExtensions", {
                                data: data?.extensions || [],
                                op: "added",
                            })
                            resolve(data)
                        })
                        .catch((error) => {
                            console.error("ERROR in getting team settings: ", error);
                            reject(error)
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument?.extensions;
                        commit("mutateRestrictedExtensions", {
                            data: docData,
                            op: "added",
                        })
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument?.extensions;
                        commit("mutateRestrictedExtensions", {
                            data: docData,
                            op: "modified",
                        })
                    } else if(type === "delete") {
                        commit("mutateRestrictedExtensions", {
                            op: "removed",
                        })
                    }
                }
            })
        }
        catch(error){
            reject(error);
        }
    })
}



export const setfinalCustomFields = ({commit}) => {
    setfinalCustomFieldsArray({commit})
}

    export const  setTimeTrackerDownload = ({commit}) => {
        return new Promise((resolve, reject) => {
            try{
               
                const queryParams = new URLSearchParams({
                    currentPage: 1,
                    search: '',
                    sort: JSON.stringify({ createdAt: 1 }),
                    source:'front'
                }).toString();
                 
                apiRequest("get",`${env.GET_TACKER}?${queryParams}`)
                .then((res) => {
                    if (res.data && res.data.status) {
                        // Commit the data array
                        commit("mutateTimeTrackerDownload", res.data.data);
                        resolve(res.data.data);
                    } else {
                        console.warn("No data found");
                        commit("mutateTimeTrackerDownload", []);
                        resolve([]);
                    }
                }).catch((error)=>{
                    console.error("ERROR in get Time Tracker Download: ", error);
                    reject(error);
                })
            }
            catch(error){
                reject(error);
            }
        })
    }

    export const setTaskStatusArray =({commit}) => {
        return new Promise ((resolve,reject) =>{
            try{
                apiRequest('get',env.TASK_STATUS_SETTING_TEMPLATE)
                    .then((res) => {
                        if(res.status === 200){
                            const result = res.data;
                            resolve(result[0])
                            result.forEach((x) => {
                                commit("mutateTaskStatusArray",{
                                    data: {...x},
                                    op: "added",
                                })
                            })
                        }else{
                            resolve([])
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } catch(error) {
                reject(error)
            }
        })  
    }

    export const setProjectStatusArray =({commit}) => {
        return new Promise ((resolve,reject) =>{
            try{
                let options = {
                    subCollection : dbCollections.SETTINGS,
                    watchFilter: {
                        filter: {
                            $or: [
                                {
                                    'operationType': 'delete'
                                },
                                {
                                    'operationType': { $in: ['insert', 'update', 'replace'] },
                                    'fullDocument.name':settingsCollectionDocs.PROJECT_STATUS
                                }
                            ]
                        }
                    }
                }
    
                mongodbSnapshot(options,({error, data, type}) => {
                    if(error) {
                        reject(error)
                    } else {
                        if(type === "inital") {
                            apiRequest('get',env.PROJECT_STATUS_SETTING_TEMPLATE)
                            .then((res) => {
                                if(res.status === 200){
                                    const result = res.data;
                                    resolve(result[0])
                                    result.forEach((x) => {
                                        commit("setProjectStatusArray",{
                                            data: {...x},
                                            op: "added",
                                        })
                                    })
                                }else{
                                    resolve([])
                                }
                            })
                            .catch((error) => {
                                reject(error)
                            })
                        } else if(type === "insert") {
                            const docData = data.fullDocument;
                            commit("setProjectStatusArray", {
                                data: {...docData, _id: docData._id},
                                op: "added",
                            })
                        } else if(type === "update" || type === "replace") {
                            const docData = data.fullDocument;
                            commit("setProjectStatusArray", {
                                data: {...docData, _id: docData._id},
                                op: "modified",
                            })
                        } else if(type === "delete") {
                            const docId = data.documentKey._id;
                            commit("setProjectStatusArray", {
                                data: {_id: docId},
                                op: "removed",
                            })
                        }
                    }
                })
            } catch(error) {
                reject(error)
            }
        })  
    }
    export const setCurrencyArray =({commit}) => {
        return new Promise ((resolve,reject) =>{
            try{
                let currencyArray = [];
                apiRequest("get",env.CURRENCY).then((response) => {
                    if(response.status === 200){
                        currencyArray = response.data;
                        commit("setCurrencyArray", {data:currencyArray,op:'inital'});
                        resolve(currencyArray);
                    }else{
                        resolve([]);
                    }
                }).catch((error) => {
                    console.error("ERROR in get setting currency: ", error);
                    reject(error);
                })
            } catch(error) {
                console.error("ERROR in get setting currency: ", error);
                reject(error);
            }
        })
    }

    export const setProjectStatus = ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                apiRequest("get", env.API_PROJECT_STATUS_TEMPLATE).then((response) => {
                    const data = response.data.data;
                    resolve(data)
                    data.forEach((x) => {
                        commit("mutateProjectStatus", {
                            data: { ...x },
                            op: "added",
                        })
                    })
                }).catch((error) => {
                    reject(error);
                })
            }
            catch (error) {
                reject(error);
            }
        })
    }

    export const setTaskTypeArray =({commit}) => {
        return new Promise ((resolve,reject) =>{
            try{
                apiRequest('get',env.TASK_TYPE_SETTING_TEMPLATE)
                .then((res) => {
                    if(res.status === 200){
                        const result = res.data;
                        resolve(result[0])
                        result.forEach((x) => {
                            commit("setTaskTypeArray",{
                                data: {...x},
                                op: "added",
                            })
                        })
                    }else{
                        resolve([]);
                    }
                }).catch((error) => {
                    reject(error)
                })
            } catch(error) {
                reject(error)
            }
        })  
    }


    export const setChargeBeePrice =({commit}) => {
        return new Promise ((resolve,reject) =>{
            try{
                let obj = {
                    type: "find",
                    collection: dbCollections.SUBSCRIPTIONPLAN,
                    data:[{},{sort:{sortIndex: 1}}],
                    global: true
                }
                mongoQuery.mongodbCrudOperations(obj).then((res)=>{
                    commit("setChargeBeePrice",res);
                    resolve(res);
                }).catch((error)=>{
                    console.error("Error GET SUBSCRIPRION PLAN",error)
                    reject(error)
                })
            } catch(error) {
                reject(error)
            }
        })  
    }


    export const setplanFeatureDisplay =({commit}) => {
        return new Promise ((resolve,reject) =>{
            try{
                let obj = {
                    type: "find",
                    collection: dbCollections.PLANFEATUREDISPLAY,
                    data:[{}],
                    global: true
                }
                mongoQuery.mongodbCrudOperations(obj).then((res)=>{
                    commit("setplanFeatureDisplay",res);
                    resolve(res);
                }).catch((error)=>{
                    console.error("Error GET SUBSCRIPRION PLAN",error)
                    reject(error)
                })
            } catch(error) {
                reject(error)
            }
        })  
    }