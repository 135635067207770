const billingHistory = [];
const upgradeRoute = [];
const upgradeTab = false;
const checkoutRoute = [];

export default {
    billingHistory,
    upgradeRoute,
    upgradeTab,
    checkoutRoute
};
