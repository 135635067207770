<template>
    <SpinnerComp :is-spinner="isSpinner"/>
    <div v-if="!currentCompany?.planFeature?.actitvityView">
        <UpgradePlan
            :buttonText="$t('Upgrades.upgrade_your_plan')"
            :lastTitle="$t('Header.to_unlock_activity_view')"
            :secondTitle="$t('Upgrades.unlimited')"
            :firstTitle="$t('Upgrades.upgrade_to')"
            :message="$t('Upgrades.the_feature_not_available')"
        />
    </div>
    <div class="activity-log" v-else>
        <div class="activity-scroll">
            <div v-for="data in activityLog" :key="data.id" class="main-activity">
                <ActivityContent :data="data" :key="data.id"/>
            </div>
            <div class="d-flex mt-1" v-if="activityLog.length > 9 && isVisibleLoadMoreButton && activityLog.length % limit === 0">
                <button @click="commonGetQuery(true)" class="btn-class cursor-pointer">{{ $t('Header.load_more') }}</button>
            </div>
            <div v-if="activityLog.length === 0 && !isSpinner" class="d-flex justify-content-center">
                <span>{{ $t('ProjectSlider.no_activity_log_found') }}</span>
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineComponent,defineProps,inject,ref,onMounted, watch, computed } from "vue";
import moment from "moment";
import { useGetterFunctions } from '@/composable/index';
import ActivityContent from "@/components/molecules/ActivityLogContent/ActivityContent.vue";
import SpinnerComp from '@/components/atom/SpinnerComp/SpinnerComp.vue';
import UpgradePlan from '@/components/atom/UpgradYourPlanComponent/UpgradYourPlanComponent.vue';
import { useRoute } from "vue-router";
import {useStore} from 'vuex'
import { apiRequest } from '../../../services';
import * as env from '@/config/env';
const { getUser } = useGetterFunctions();
const defaultpic = inject("$defaultUserAvatar");

const {getters}  = useStore()

const currentCompany = computed(() => getters["settings/selectedCompany"])

defineComponent({
    name: "ActivityLog",
    components: {
        ActivityContent,
        SpinnerComp
    },
})
const props = defineProps({
    dataObj: Object,
    fromProject: {
        type: Boolean,
        required: true,
        default: undefined
    },
});
const activityLog = ref([]);
const lastVisible = ref(null);
const isVisibleLoadMoreButton = ref(true);
const isSpinner = ref(false);
const skip = ref(0);
const limit = ref(10);
const route = useRoute()

watch(() => props.dataObj,(prValue) => {
    if(props.fromProject === true && prValue._id !== route.params.id){
        commonGetQuery();
    }
})

function commonGetQuery(loadMore = false) {
    isSpinner.value = true;
    if (loadMore) {
        skip.value += limit.value;
    } else {
        skip.value = 0;
        activityLog.value = [];
    }

    // Determine the projectId based on the context
    const projectId = props.fromProject ? props.dataObj._id : props.dataObj.ProjectID;

    const requestParams = new URLSearchParams({
        fromProject: props.fromProject,
        projectId: projectId,
        ...(props.fromProject ? {} : { taskId: props.dataObj._id }),
        skip: skip.value,
        limit: limit.value
    }).toString();

    apiRequest("get", `${env.ACTIVITYLOG}?${requestParams}`)
        .then((response) => {
            const res = response.data;

            if (res.length === 0) {
                isVisibleLoadMoreButton.value = false;
                isSpinner.value = false;
                return;
            }

            res.forEach((element) => {
                let dataObject = { ...element };
                dataObject.displayDate = dataObject.createdAt == undefined 
                    ? moment(new Date()).format('ddd, MMM DD, YYYY hh:mm:ss A') 
                    : moment(new Date(dataObject?.createdAt)).format('ddd, MMM DD, YYYY hh:mm:ss A');
                const user = getUser(dataObject.UserId);
                dataObject.userData = {
                    image: user.Employee_profileImageURL ? user.Employee_profileImageURL : defaultpic,
                    title: user.Employee_Name
                };
                activityLog.value.push(dataObject);
            });

            lastVisible.value = res[res.length - 1];
            isSpinner.value = false;
        })
        .catch((error) => {
            console.error(`ERROR in getting activity log of ${props.fromProject == false ? 'task' : 'project'}`, error);
            isSpinner.value = false; 
        });
}
onMounted(() => {
    if(props.fromProject == undefined) {
        throw new Error("fromProject is required");
    }
    commonGetQuery();
})
</script>
<style src="./style.css"></style>