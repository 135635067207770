import { apiRequest } from '@/services';
import * as env from '@/config/env';
export const getTours = async ({ commit }, payload) => {
  let additionalData = {
    "isProjectTour" : {
      tourModalTitle : "have_brief_tour_project",
      tourModalMessage : "look_how_to_create_project"
    },
    "isTaskTour": {
      tourModalTitle : "have_brief_task",
      tourModalMessage : "look_how_to_create_task"
    },
    "isProjectAndNavbarTour": {
      tourModalTitle : "have_brief_tour_alianhub",
      tourModalMessage : "look_how_to_create"
    },
    "isProjectViewTour" : {
      tourModalTitle : "have_brief_tour_alianhub_view",
      tourModalMessage : "look_how_to_create"
    },
    "isProjectLeftViewTour" : {
      tourModalTitle : "have_brief_tour_alianhub_left_side",
      tourModalMessage : "look_how_to_create"
    }
  }

  try {
    apiRequest("get", env.TOURS)
    .then((response) => {
      if(response.status !== 200){
        console.error(response?.response?.data?.message);
        return 
      }
      let tourList = response.data; // Assuming the response data is the list of tours
      if (!tourList || tourList.length <= 0) return;
      let tourStatus = payload ?? {};

      for (let tour of tourList) {
        if (Object.hasOwnProperty.call(tourStatus, tour.id)) {
          tour.isCompleted = tourStatus[tour.id];
        }
        if (additionalData[tour.id]) {
          tour.tourModalTitle = additionalData[tour.id].tourModalTitle;
          tour.tourModalMessage = additionalData[tour.id].tourModalMessage;
        }
      }
      commit('mutateTours', tourList);
    })
    .catch((error) => {
      console.error("Error GET TOURS GET", error);
    });
  } catch (error) {
    console.error("Error GET TOURS GET", error);
  }
};
